// Solarized colours.
$base03:    #002b36;
$base02:    #073642;
$base01:    #586e75;
$base00:    #657b83;
$base0:     #839496;
$base1:     #93a1a1;
$base2:     #eee8d5;
$base3:     #fdf6e3;
$yellow:    #b58900;
$orange:    #cb4b16;
$red:       lighten(#dc322f, 5%);
$magenta:   #d33682;
$violet:    #6c71c4;
$blue:      #268bd2;
$cyan:      #2aa198;
$green:     #859900;

$header-lighten: 5%;

// Mixin to apply Solarized to something. Pass the values backwards for Solarized Dark.
@mixin rebase-bg($rebase03, $rebase02, $rebase01, $rebase00, $rebase0, $rebase1, $rebase2, $rebase3) {
    background-color: $rebase03;
    &, * { color: $rebase0; }
}
@mixin rebase($rebase03, $rebase02, $rebase01, $rebase00, $rebase0, $rebase1, $rebase2, $rebase3) {
    background-color: $rebase03;
    &, * { color: $rebase0; }
}

// Mixin to accentize something.
@mixin accentize($accent) {
    a, a:active, a:visited, a:hover, code { color: $accent; }
    h1, h2, h3, h4, h5, h6 { color: $accent; }
}

// Default to Solarized Dark. If the browser requests light mode, reset to normal Solarized.
body {
    @include rebase($base03, $base02, $base01, $base00, $base0, $base1, $base2, $base3);
    @include accentize($red);
}
#header {
    @include rebase(
        lighten($base03, $header-lighten),
        lighten($base02, $header-lighten),
        lighten($base01, $header-lighten),
        lighten($base00, $header-lighten),
        lighten($base0, $header-lighten),
        lighten($base1, $header-lighten),
        lighten($base2, $header-lighten),
        lighten($base3, $header-lighten),
    );
}

@media (prefers-color-scheme: light) {
    body {
        @include rebase($base3, $base2, $base1, $base0, $base00, $base01, $base02, $base03);
    }
    #header {
        @include rebase(
            darken($base3, $header-lighten),
            darken($base2, $header-lighten),
            darken($base1, $header-lighten),
            darken($base0, $header-lighten),
            darken($base00, $header-lighten),
            darken($base01, $header-lighten),
            darken($base02, $header-lighten),
            darken($base03, $header-lighten),
        );
    }
}

body {
    color-profile: sRGB;
    rendering-intent: auto;
}

a {
    text-decoration: none;
    &:hover { text-decoration: underline; }
}

// Apply solarized to the header, but lightened a bit.
#header {
    padding-bottom: 10pt;
    margin-bottom: 10pt;
    //text-align: right;

    header {
        &, h2 { font-weight: 100; }
        p, h2 { margin-bottom: 0; }
    }
}

.meme {
    white-space: nowrap;
}

.posts {
    a {
        display: block;
        padding-left: 10pt;
        margin-left: -10pt;
        border-left: 2px solid transparent;
        h2 { margin-bottom: 0; }
        &:hover { text-decoration: none; border-left-color: inherit; }
    }
}

article {
    header {
        h1, h2 { margin-bottom: 0; font-weight: 200; }
        p { font-weight: 100; font-size: 0.8rem; }
        margin-bottom: 10pt;
    }
}
